.eta {
  width: 100vw;
  height: auto;
}
.eta-nav {
  position: absolute;
  width: auto;
  z-index: 10;
  margin-top: 100px;
  box-shadow: 4px 4px 6px 0px grey;
  background: white;
}

.eta-body {
  display: flex;
  height: 955px;
}

.eta-nav {
  height: 855px;
}

.nav-space {
  width: 105px;
}

.eta-body-area {
  width: 1920px;
  background: #f3f2f2;
  overflow-y: auto;
  margin-top: 100px;
}

.nav-option {
  display: flex;
}

.nav-logo {
  font-family: sans-serif;
  font-size: 20px;
  margin: 15px;
  width: 65px;
  text-align: center;
  padding: 10px 0;
  background: #1c3d71;
  font-weight: 600;
  color: whitesmoke;
  border-radius: 5px;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999; /* Ensure it's above other elements */
}

.nav-logo-selected {
  font-family: sans-serif;
  font-size: 20px;
  margin: 15px;
  width: 65px;
  text-align: center;
  padding: 10px 0;
  background: #a42f28;
  font-weight: 600;
  color: whitesmoke;
  border-radius: 5px;
  cursor: pointer;
}

.nav-opt-details {
  padding: 5px;
  font-size: 25px;
  font-weight: 200;
  margin: 15px 0;
  width: 220px;
  border-bottom: 1px solid grey;
}

.nav-opt-details-hover {
  padding: 5px;
  font-size: 25px;
  margin: 15px 0;
  width: 220px;
  font-weight: 400;
  color: #bd1b26;
  border-bottom: 1px solid #bd1b26;
}

.nav-option-hover {
  margin: 15px;
  padding: 10px 0;
  position: absolute;
  height: 29px;
  width: 65px;
}

.dash-head {
  /* font-size: 20px; */
  font-size: 1.2rem;
  font-family: sans-serif;
  margin: 30px 30px;
  /* color: #193c6c; */
  color: #202123;
  letter-spacing: 0.3px;
}

.mail-receipt {
  margin-left: 12px;
  margin-bottom: 20px;
  /* width: 1180px; */
  width: 98%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.container1 {
  margin-left: 14px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
}

.container2 {
  /* margin-left: 30px; */
  display: flex;
  flex-direction: column-reverse;
  width: 94%;
  margin-right: 14px;
}

.date-pickers {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card-dev {
  margin-left: 30px;
  margin-top: 40px;
  width: 94%;
  display: flex;
  justify-content: space-between;
}

.loaderParent {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  height: 120px;
  align-items: center;
}

.data-status {
  width: 21%;
  /* font-size: 17px !important; */
  word-wrap: break-word;
}

.data-date {
  width: 25%;
  /* font-size: 17px !important; */
  font-family: Montserrat-Medium;
  font-size: 12px;
}

.data-date inner-data-head {
  font-family: Montserrat-Medium !important;
  font-size: 14px !important;
}

.data-status,
.data-date {
  margin-left: 46px;
  color: #193c6c;
}

.inner-data-head {
  font-weight: 600;
}

.dash-mail {
  margin-left: 30px;
  width: 500px;
}

.dash {
  /* margin: 12px; */
  background-color: #ffffff;
  border-radius: 8px;
  /* margin-left: 50px;
    margin-bottom: 24px; */
}

.trackemaildash {
  padding-top: 5px;
  margin: 12px;
  background-color: #ffffff;
  border-radius: 8px;
  /* margin-left: 50px;
    margin-bottom: 24px; */
}

.dash-mail-submit {
  padding: 15px 40px;
  font-size: 20px;
  border: 0;
  font-weight: 600;
  color: whitesmoke;
  background: #1c3d71;
  border-radius: 5px;
  cursor: pointer;
}

.login-sign-up {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  background-size: 100vw 100vh;
}

.ls-area {
  width: 1050px;
  height: 570px;
  box-shadow: 2px 2px 10px 1px grey;
  border-radius: 10px;
  margin-top: 20vh;
  display: flex;
}
@media only screen and (max-height: 900px) {
  .ls-area {
    margin-top: 10vh;
  }
}

.ls-body-l {
  width: 30%;
  border-radius: 10px 0 0 10px;
  background: white;
}

.ls-body-r {
  width: 70%;
  border-radius: 0 10px 10px 0;
  background: #dadada;
}

.ls-body-l,
.ls-body-r {
  height: 100%;
  display: flex;
  justify-content: center;
}

.ls-logo {
  height: 260px;
  width: 300px;
  margin-top: 155px;
  background-size: 300px 260px;
}

.user {
  margin-right: 70px;
  margin-top: 37px;
  display: flex;
  font-size: 20px;
}

.user-logo {
  font-size: 20px;
  color: #00d5ff;
  font-weight: 500;
}

.user-name {
  margin-left: 20px;
  font-size: 20px;
  color: #00d5ff;
  font-weight: 500;
}

.logout {
  font-size: 20px;
  margin-left: 20px;
  cursor: pointer;
  color: red;
}

.no-data {
  text-align: center;
  width: 100%;
  color: #193c6c;
  font-size: 17px;
  font-weight: 600;
}

.mail-info {
  color: #fff;
  font-family: Montserrat-Medium;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
}

.MuiOutlinedInput-input {
  padding: 8px 14px !important;
}

.status-option {
  color: #193c6c;
  width: 1000px;
}

.search-user-cont {
  margin: 30px;
}

.search-user-cont-4 {
  margin-left: -100px;
  margin-top: -40px;
}

.search-user-cont-right {
  text-align: right; /* Align content to the right within the container */
  margin: 30px 55px;
}

.search-user-cont-1 {
  margin: 30px;
  margin-left: -400px;
}

.search-user-cont-2 {
  margin: 30px;
  margin-left: 0px;
}

.search-user-cont-3 {
  margin: 30px;
  margin-left: 650px;
  margin-top: 70px;
}

.search-user-cont-1-right {
  text-align: right; /* Align content to the right within the container */
  margin: 10px 55px;
}

.container {
  display: flex;
  justify-content: space-between;
}

.search-user-label {
  /* text-align: left; */
  font-size: 17px;
  /* margin-top: -10px; */
  color: #193c6c;
}
.search-user-input {
  padding-left: 2000px;
}
.selected-status {
  background: #ffffff;
}

.search-user-input,
.search-user-input:focus {
  height: 30px;
  font-size: 15px;
  padding: 10px 10px;
  resize: none;
  width: 300px;
  border: none;
  font-family: sans-serif;
  background: #ffffff;
  color: #222121;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 2px 2px 6px grey;
}

.search-user-submit {
  font-size: 18px;
  padding: 8px 10px;
  font-family: sans-serif;
  border: 0;
  background: #1a3d70;
  font-weight: 600;
  color: whitesmoke;
  border-radius: 5px;
  box-shadow: 2px 2px 7px grey;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 6px grey;
}

/* .css-24jl84-MuiButtonBase-root-MuiAccordionSummary-root{
    min-height: 35px !important;
  }
  
  .css-24jl84-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 35px !important;
  } */

.MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 20px !important;
}

/* .css-46bh2p-MuiCardContent-root:last-child{
    padding-bottom: 16px !important;
  }

  .MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 20px !important;
  }

  .MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 20px !important;
  } */

/* .MuiTableCell-root {
    padding: 8px !important;
    padding-left: 16px !important;
  } */

/* .MuiButtonBase-root-MuiButton-root.Mui-disabled {
    opacity: .5 !important;
    cursor: not-allowed !important;
    color: #fff !important;
    background-color: #193C6D !important;
  } */

.clear-user {
  font-size: 12px;
  cursor: pointer;
  color: red;
}
.show-filter {
  width: 85%;
}

.page-cont {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  overflow-x: auto;
}

.page-items {
  display: flex;
  justify-content: center;
}

.page-item {
  width: 35px;
  height: 35px;
  background: #b6b6b6;
  text-align: center;
  line-height: 35px;
  margin: 5px;
  border-radius: 35px;
  font-family: sans-serif;
  color: white;
  font-weight: 800;
  cursor: pointer;
  border: 2px solid #08347c;
}

.page-item-selected {
  width: 35px;
  height: 35px;
  background: #193c6c;
  text-align: center;
  line-height: 35px;
  margin: 5px;
  border-radius: 35px;
  font-family: sans-serif;
  color: white;
  font-weight: 800;
  cursor: pointer;
  border: 2px solid #8d8a8a;
}

.no-data-outer-table {
  text-align: center;
  padding: 20px;
  font-weight: 500;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #193c6c;
}

.comment-dialog {
  padding-top: 0 !important;
}

.tablebodycell {
  font-family: "Montserrat-Medium" !important;
  font-size: 12px !important;
  padding: 6px 16px !important;
  white-space: nowrap !important;
}

.tablebodycellcomment {
  font-family: "Montserrat-Medium" !important;
  font-size: 12px !important;
  padding: 6px 16px !important;
}

.tablebodycellcomment1 {
  font-family: "Montserrat-Medium" !important;
  font-size: 12px !important;
  padding: 6px 16px !important;
  max-width: 150px; /* Adjust the width as needed */
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 24px;
  padding: 16px;
  word-wrap: break-word;
  max-width: 90%;
  max-height: 10px;
  max-height: 500px; 
  overflow-y: auto;
}
.arrow-ribbon {
  position: relative;
  display: inline-block;
  padding: 1px 20px 1px 10px;
  color: white;
  background-color: #007bff; 
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
}

.arrow-ribbon::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -8px; /* Adjust as needed */
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #007bff; /* Match the background color */
  transform: translateY(-50%);
}

.arrow-ribbon::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px; /* Adjust as needed */
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid white; /* Color of the flag */
  transform: translateY(-50%);
  z-index: 1; /* Ensure it appears on top of the other triangle */
}

.arrow-ribbon.today {
  background-color: orange; /* Change to the color you want for today */
}

.arrow-ribbon.today::after {
  border-right-color: orange; /* Match the background color */
}

.arrow-ribbon.tomorrow {
  background-color: red; /* Change to the color you want for tomorrow */
}

.arrow-ribbon.tomorrow::after {
  border-right-color: red; /* Match the background color */
}

.arrow-ribbon.other-date {
  background-color: #4CAF50; /* Customize your color */
}

.arrow-ribbon.other-date::after {
  border-right-color: #4CAF50; /* Match the background color */
}

.custom-tooltip {
  background-color: #ffffff !important;
  color: #000000 !important;
  max-width: 500px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1) !important;
}

.custom-tooltip-arrow::before {
  background-color: #ffffff !important;
}

.divider {
  margin: 0 2.5% 0 2% !important;
  border-bottom-width: 2px !important;
  border-color: #000000 !important;
}

.campaign-divider {
  margin: 0 2% 0 1.5% !important;
  border-bottom-width: 2px !important;
  border-color: #000000 !important;
}

.tooltip-divider {
  margin-bottom: 5px !important;
  border-bottom-width: 1px !important;
  border-color: #000000 !important;
}

.custom-dropdown .p-dropdown-label {
  font-size: 12px; /* Adjust the font size as needed */
  width: 120px; 
  border-radius: 20px;
}

.p-checkbox .p-checkbox-box {
  height: 18px !important;
  width: 18px !important;
}	

.custom-panel-class .p-treeselect-items-wrapper {
  max-height: 200px !important; 
}

.p-treeselect-items-wrapper	{
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;

}