.tablebodycell{
    font-family: 'Montserrat-Medium'!important;
    font-size: 12px !important;
    white-space: nowrap !important;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999; /* Ensure it's above other elements */
}