body {
  padding: 0 !important;
  margin: 0px;
  /* padding: 0px; */
  height: 100%;
  width: 100%;
}

html {
  /* height: 100%; */
  width: 100%;
  overflow-y: auto;

}

::-webkit-scrollbar{
  width: 4px;
  color: #193C6D;
  height: 1px; 
}
::-webkit-scrollbar-thumb {
  background-color:  #193C6D;;  
  border-radius: 4px ;    
}
::-webkit-scrollbar-thumb:hover {
background-color: "#555";
}

#root {
  height: 100%;
  width: 100%;
}

.dinnerBg {
  background-image: url("./assets/images/Lunch.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;  
  border: 1px solid black ;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local('Montserrat-SemiBold'),
    url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'), url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'), url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Montserrat-Light';
  src: local('Montserrat-Light'), url('./assets/fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: thin;
}
