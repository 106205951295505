/* .formbody{
    border: 1px solid rgba(25,60,109,.5)!important;
    padding: 20px!important;
   } */
   
   .header {
     z-index: 12;
     width: 100%;
     height: auto;
     display: flex;
     box-shadow: 2px 2px 12px 4px grey;
     position: fixed;
     background: white;
     justify-content: space-between;
   }
   
   /* .head-left {
     width: 5%;
   } */
   
   .head-reight {
     width: 60%;
   }
   
   .logo {
     /* background: url("./../../../public/td-logo.jpeg"); */
     background-repeat: no-repeat;
     height: 100px;
     width: 155px;
     background-size: 155px 100px;
     margin-left: 70px;
   }
   
   .heading {
     font-size: 25px;
     margin: 33px 0px;
     color: #193c6c;
     font-weight: 500;
   }
   
   .body {
     width: 100%;
     height: auto;
     /* position: absolute; */
   }
   
   .body-left {
     width: 100%;
     display: flex;
     justify-content: center;
   }
   
   .lbc {
     width: 98%;
     margin-left: 2.5%;
   }
   
   .lbc-subject {
     height: 131px;
     width: 82%;
     margin-top: 15px;
     display: flex;
     justify-content: space-between;
   }
   
   .lbc-l,
   .lbc-r {
     width: 100%;
   }
   
   .lbc-lr {
     width: 40%;
   }
   
   .body-right {
     width: 100%;
     display: flex;
     justify-content: center;
     /* background: #f3f2f2; */
   }
   
   .brc {
     width: 100%;
     /* margin-bottom: 20px; */
   }
   
   .brcl {
     margin: 5px 0 5px 0;
     font-size: 17px;
     color: #193c6c;
     font-family: sans-serif;
   }
   
   .ql-container {
    min-height: 200px;
     height: auto !important;
     background: white;
     margin-bottom: 20px !important;
     border-radius: 0 0 8px 8px;
     font-size: 15px !important;
   }
   
   .quill {
     border-radius: 8px;
     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
     border: 2px #808080;
   }
   
   .ql-toolbar {
     border-radius: 8px 8px 0 0;
     background: white;
     padding: 3px 8px !important;
   }
   .ql-editor {
     font-family: 'Arial', sans-serif;
   }
   
   .invalid-data {
     font-size: 17px;
     color: #f05353;
     margin-bottom: 10px;
     height: 24px;
   }
   
   .submit-button {
     display: flex;
     justify-content: flex-end;
   }
   
   .button {
     font-size: 20px;
     padding: 15px 45px;
     border: none;
     border-radius: 8px;
     background: rgb(0 0 96);
     color: whitesmoke;
     cursor: pointer;
   }
   
   .data-not-valid {
     font-size: 20px;
     color: #ffffff;
     font-weight: 600;
     background: #f31818de;
     text-align: center;
     margin: 5px 0;
     width: 395px;
     position: absolute;
     top: 32%;
     right: 0;
     border-radius: 5px 0 0 5px;
     padding: 10px 0;
   }
   
   .data-valid {
     font-size: 20px;
     color: #ffffff;
     font-weight: 600;
     background: #07d513de;
     text-align: center;
     margin: 5px 0;
     width: 395px;
     position: absolute;
     top: 32%;
     right: 0;
     border-radius: 5px 0 0 5px;
     padding: 10px 0;
   }
   
   @media only screen and (max-height: 900px) {
     .data-not-valid {
       top: 45%;
     }
     .data-valid {
       top: 45%;
     }
   }
   
   .label {
     font-size: 17px;
     color: #193c6c;
     margin-top: 6px;
     margin-bottom: 5px;
     font-family: sans-serif;
   }
   
   .label2 {
     font-size: 17px;
     color: #193c6c;
     margin-bottom: 5px;
     font-family: sans-serif;
   }
   
   .label1 {
     font-size: 17px;
     color: #193c6c;
     margin-bottom: 5px;
     margin-left: 2.5%;
     font-family: sans-serif;
   }
   
   .new-input {
     height: 40px;
     font-size: 15px;
     padding: 10px 10px;
     resize: none;
     width: 95%;
     font-family: sans-serif;
     background: #ffffff;
     color: #222121;
     margin-bottom: 10px;
     border-radius: 8px;
     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
   }
   .new-input1 {
     height: 40px;
     overflow: hidden;
     font-size: 15px;
     padding: 10px 10px;
     resize: none;
     width: 97%;
     font-family: sans-serif;
     background: #ffffff;
     color: #222121;
     margin-bottom: 5px;
     /* margin-top: 30px; */
     border-radius: 8px;
     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
   }
   .new-input2 {
     height: 42px;
     font-size: 14px;
     padding: 10px 10px;
     resize: none;
     width: 97%;
     font-family: sans-serif;
     background: #ffffff;
     color: #222121;
     margin-bottom: 5px;
     border-radius: 8px;
     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
   }
   .new-inputCapital {
     height: 40px;
     overflow: hidden;
     font-size: 15px;
     padding: 10px 10px;
     resize: none;
     width: 95%;
     font-family: sans-serif;
     background: #ffffff;
     color: #222121;
     margin-left: 2.5%;
     border-radius: 8px;
     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
   }
   
   .required::after {
     content: ' *';
     color: red;
   }
   
   .send-bulk-cont {
     margin: 5px 0 5px 0;
     font-size: 17px;
     color: #193c6c;
     font-family: sans-serif;
   }
   
   .send-bulk-cont input {
     margin-right: 10px;
   }
   .Cancel {
    font-size: 14px;
    font-weight: 400;
    font-family: Montserrat-Medium;
    text-transform: capitalize;
    padding: 10px 30px;
    margin-right: 10px;
    border: none;
    border-radius: 25px;
    background: rgb(226, 226, 226);
    color: #000000;
    cursor: pointer;
  }
   .Submit {
     font-size: 14px;
     font-weight: 400;
     font-family: Montserrat-Medium;
     text-transform: capitalize;
     padding: 10px 30px;
     border: none;
     border-radius: 25px;
     background: rgb(25, 60, 109);
     color: whitesmoke;
     cursor: pointer;
     white-space: nowrap;
   }
   .SubmitMain {
     display: flex;
     justify-content: flex-end;
     padding-bottom: '10px';
   }
   .error-text {
     color: #f05353;
     font-family: Montserrat-Regular;
     font-size: 12px;
     /* margin-left: 130px; */
   }
   .error-subject {
     margin-left: 10px;
     font-family: Montserrat-Regular;
     font-size: 10px;
   }
   .editor-container .ql-editor {
     text-transform: capitalize;
   }

   .emaildivider{
    border-bottom-width: 2px !important;
    border-color: #000000 !important;
   }

   .attachfilebox{
    background-color: #f5f5f5;
    border: 1px solid transparent;
    font-weight: bold;
    margin: 0 0 9px;
    overflow-y: hidden;
    padding: 4px 4px 4px 8px;
    max-width: 448px;
    display: flex;
   }

.ql-editor a {
    text-decoration: none !important;
}

.custom_ck {
  height: 260px;
  border-radius: 20px !important;
  width: 100%;
}
.custom_ck .ck-editor__editable_inline:not(.ck-comment__input *) {
  width: 100%;
  height: 210px;
  overflow-y: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 12px 29px 0px;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.ck .ck-dialog-overlay{
  z-index: 10002 !important;
}

.custom_ck a {
  text-decoration: inherit;
}

.ck .ck-reset .ck-dropdown__panel .ck-dropdown__panel_se .ck-dropdown__panel-visible{
  height: 150px !important;
  overflow: scroll !important;
}

iframe#webpack-dev-server-client-overlay{display:none!important}
   