.calendar-todo-list {
    text-align: left;
    padding: 0;
    list-style: none;
  }
  
  .calendar-todo-list li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .calendar-todo-item-badge {
    vertical-align: top;
    margin-top: 8px;
    width: 6px;
    height: 6px;
  }  

  .rs-calendar-table-cell {
    height: 10px; /* Adjust the height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  }

.event {
    font-family: Montserrat-SemiBold;
    font-size: 12px;
    margin-top: 2px;
}

.event1 {
  font-family: Montserrat-SemiBold;
  font-size: 14px;
}

.event2 {
  font-family: Montserrat-Regular;
  font-size: 13px;
}

/* .event:hover {
  background-color: rgba(0, 123, 255, 1);
} */

.rbc-day-slot .rbc-event-1 {
  background-color: #ffffff !important;
  color: rgb(3, 155, 229) !important; /* Light blue text color */
  border-color: rgb(3, 155, 229) !important; /* Light blue border color */
}

.rbc-day-slot .rbc-event-1 .rbc-event-label{
  font-family: Montserrat-SemiBold;
  font-size: 12px;
  background-color: #ffffff !important;
  color: rgb(3, 155, 229) !important; /* Light blue text color */
  border-color: rgb(3, 155, 229) !important; /* Light blue border color */
}

/* Styles for the second and subsequent events in a time slot */
.rbc-day-slot .rbc-event-2 .rbc-event-label,
.rbc-day-slot .rbc-event-3 .rbc-event-label,
.rbc-day-slot .rbc-event-4 .rbc-event-label,
.rbc-day-slot .rbc-event-5 .rbc-event-label,
.rbc-day-slot .rbc-event-6 .rbc-event-label,
.rbc-day-slot .rbc-event-7 .rbc-event-label,
.rbc-day-slot .rbc-event-8 .rbc-event-label,
.rbc-day-slot .rbc-event-9 .rbc-event-label,
.rbc-day-slot .rbc-event-10 .rbc-event-label{
  font-family: Montserrat-SemiBold;
  font-size: 12px;
  background-color: rgb(3, 155, 229) !important; /* Light blue background color */
  color: #ffffff !important; /* White text color */
  border-color: #ffffff !important; /* White border color */
}

.rbc-day-slot .rbc-event-2,
.rbc-day-slot .rbc-event-3,
.rbc-day-slot .rbc-event-4,
.rbc-day-slot .rbc-event-5,
.rbc-day-slot .rbc-event-6,
.rbc-day-slot .rbc-event-7,
.rbc-day-slot .rbc-event-8,
.rbc-day-slot .rbc-event-9,
.rbc-day-slot .rbc-event-10 {
  background-color: rgb(3, 155, 229) !important; /* Light blue background color */
  color: #ffffff !important; /* White text color */
  border-color: #ffffff !important; /* White border color */
}

.custom-month-event{
  background-color: rgb(3, 155, 229) !important; /* Light blue background color */
  color: #ffffff !important; /* White text color */
  border-color: #ffffff !important; /* White border color */
}

rbc-agenda-view .rbc-agenda-time-cell {
  border-left: 1px solid #ddd; /* Change color and width as needed */
}

/* Apply border to all event cells in the agenda view */
.rbc-agenda-view .rbc-agenda-event-cell {
  border-left: 1px solid #ddd; /* Change color and width as needed */
}

/* Ensure consistent border styling for all rows */
.rbc-agenda-view tbody > tr > td {
  border-left: 1px solid #ddd; /* Change color and width as needed */
}

