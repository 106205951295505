.tablebodycell{
    font-family: 'Montserrat-Medium'!important;
    font-size: 12px !important;
    white-space: nowrap !important;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999; /* Ensure it's above other elements */
}

.modalBox-removeLead {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 24px;
  padding: 16px;
  word-wrap: break-word;
  max-width: 90%;
  max-height: 10px;
  max-height: 500px; 
  overflow-y: auto;
}

/* .p-component {
  z-index: 9999 !important;
} */

.custom-dropdown-panel {
  z-index: 9999 !important;
}